export const routeNames = {
  account: 'account',
  acquisition: 'acquisition',
  cgu: '/login/cgu',
  choicesOptions: '/choices-options',
  tmaChoicesOptions: '/choices-options/tma',
  equipmentChoicesOptions: '/choices-options/equipment',
  mixedChoicesOptions: '/choices-options/mixed',
  optionChoicesOptions: '/choices-options/option',
  documentDetails: '/documents/:categoryId/:id',
  document: '/documents/:id',
  documents: 'documents/*',
  forgotPassword: 'forgot-password',
  privacyPolicy: 'privacy-policy',
  fundingAppeal: '/funding-appeal',
  home: 'home/*',
  login: 'login/*',
  logout: '/logout',
  magicLink: '/magiclink/:token',
  messaging: '/messaging',
  onboarding: '/onboarding',
  pictures: 'pictures/*',
  publicationPictureDetails: '/home/picture/:publicationId/:pictureId',
  recoverPassword: '/login/recover-password',
  services: 'services/*',
  servicesAddons: '/addons/',
  serviceDetails: ':id',
  servicesAddonsDetails: '/services/addons/:addonId',
  servicesAddonsDetailsModal: 'addons/:addonId?module=:moduleName',
  snags: '/snags',
  invitation: '/invitation/:invitationToken',
  inaccessible: '/error/inaccessible',
  personalization: '/personalization',
  checklist: '/checklist',
}

export const routeNavigate = {
  login: '/login',
  home: '/home',
  messaging: '/messaging',
  serviceDetails: '/services/:id',
} as const

export const modulesRootsNames = {
  home: 'home/*',
  login: 'login/*',
  pictures: 'pictures/*',
  account: 'account/*',
  documents: 'documents/*',
  services: 'services/*',
  messaging: 'messaging/*',
  fundingAppeal: 'funding-appeal/*',
  choicesOptions: 'choices-options/*',
  snags: 'snags/*',
  personalization: 'personalization/*',
  checklist: 'checklist/*',
  acquisition: 'acquisition/*',
  logout: 'logout', // Not a module but in App.tsx
  forgotPassword: 'login/forgot-password',
  magicLink: 'magiclink/:token',
}

export const routesWithoutMenu = routeNames.acquisition
